import React, { useContext } from 'react'
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { faGlobe, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "auth/AuthContext";
import { types } from "types/types";
import { useLang } from "language";
import { inIframe } from 'helpers/helpers';

export const NavHome = (props) => {

    const { handleOpenModal_Contacto } = props;

    const { user, dispatch } = useContext(AuthContext);

    const Lang = useLang()

    const handdleChangeLanguage = (lang) => {
        dispatch({
            type: types.changeLanguage,
            payload: {
                ...user,
                lang
            }
        })
    }

    return (
        <Navbar bg="white" expand="lg" className="small-navbar ml-auto">
            <Container fluid>
                <Navbar.Brand>

                    {user.lang === 'CA' &&
                        <img src="/logo_test_rado.png" width="170" height="35" className="d-inline-block align-top" alt="Logo" />
                    }

                    {user.lang === 'ES' &&
                        <img src="/logo_test rado_CAST.png" width="170" height="35" className="d-inline-block align-top" alt="Logo" />
                    }
                </Navbar.Brand>
                {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">*/}
                    <Nav className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <Nav.Link onClick={() => {
                            handleOpenModal_Contacto({
                                action: 'EXTRA',
                            })
                        }} className="text-dark">
                            {Lang('CONTACTAR')}
                        </Nav.Link>

                        {
                            process.env.REACT_APP_MULTILANGUAGE === 'true'  && inIframe() &&
                            <NavDropdown align="end" className="text-dark" title={<span className="text-dark"> <FontAwesomeIcon icon={faGlobe} className='me-2' /> {user.lang}</span>} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => handdleChangeLanguage('ES')}>{Lang('ESPANIOL')}</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => handdleChangeLanguage('CA')}>{Lang('CATALAN')}</NavDropdown.Item>
                            </NavDropdown>
                        }

                    </Nav>
                {/*</Navbar.Collapse>*/}
            </Container>
        </Navbar>
    )
}