const lang = {
    CA: {
        INICIAR_SESION: "Iniciar sessió",
        INSCRIPCION: "Inscripció",
        OLVIDASTE_CONTRASENA: "Has oblidat la teva contrasenya?",
        AVISO_LEGAL: "Avís Legal",
        POLITICA_PRIVACIDAD: "Política de Privacitat",
        POLITICA_COOKIES: "Política de Cookies",
        ESPANIOL: "Espanyol",
        CATALAN: "Català",
        CONTRASENIA: "Contrasenya",
        PROCESOS: "Processos",
        PROCESO: "Procés",
        CAMBIAR_CONTRASENIA: "Canviar contrasenya",
        SALIR: "Sortir",
        NUM: "Núm",
        LISTA_DE: "Llista de",
        FILTRO: "Filtre",
        BUSCAR: "Cercar",
        PLANTILLAS_MENSAJES: "Plantilles Missatges",
        VER_MAS: "Veure més dels nostres serveis",
        COLABORACION: "Amb la col·laboració de",
        NOMBRE_DEL_TRAMITADOR: 'Nom del tramitador',
        DESDE: "Des",
        HASTA: "Fins",
        ESTADO: "Estat",
        FECHA_ESTADO: "Data estat",
        AVISAR_PENDIENTES_FIRMAR: "Avisar pendents de signar",
        DATOS_GENERALES: "Dades generals",
        HISTORICO: "Històric",
        PLANTILLAS: "Plantilles",
        DESCRIPCION_PLANTILLA: "Descripció de la plantilla",
        REMITENTE: "Remitent",
        CORREO_REMITENTE: "Correu del remitent",
        ASUNTO: "Assumpte",
        MENSAJE: "Missatge",
        CODIGOS_ADICIONALES: "Codis addicionals",
        PERTENECE_PROCESO_DE: "Pertany al procés de",
        TRAMITADOR: "Tramitador",
        TAMANIO: "Mida",
        FORMATO: "Format",
        DESCARGAR: "Descarregar",
        DOCUMENTO: "Document",
        USUARIOS: "Usuaris",
        LOGS: "Logs",
        MANTENIMIENTO: "Manteniment",
        ACTUALIZAR: "Actualitzar",
        COPYRIGHT: "© Col·legi d'Aparelladors, Arquitectes Tècnics i Enginyers d'Edificació de Barcelona",
        ID_INTERNO_PROCESO: "ID",
        NIF_TRAMITADOR: "NIF Tramitador",
        FINALIZADO: "Finalitzat",
        FECHA_MODIFICACION: "Data modificació",
        NUMERO_DE_LA_MUESTRA: 'Núm. de la mostra',
        AGREGAR_PLANTA: 'Afegir planta',
        TIPO_PLANTA: 'Tipus de planta',
        EDITAR_PLANTA: 'Editar planta',
        GUARDAR: 'Desar',
        PRUEBAS_DE_LABORATORIO: `Proves de laboratori`,
        COLEGIADOS: 'Col·legiats',
        OTROS_USUARIOS: 'Altres usuaris',
        TEST_RADON: 'Test radó',
        TEST_RADON_NUEVO: 'Nou Test Radó',
        TEST_RADON_WIZAR_NIVEL1: 'Dades per a la sol·licitud',
        TEST_RADON_WIZAR_NIVEL2: 'Determinació detectors',
        TEST_RADON_WIZAR_NIVEL3: 'Facturació i pagament',
        TEST_RADON_WIZAR_NIVEL4: 'Mesurament',
        TEST_RADON_WIZAR_NIVEL5: 'Resultat',
        TEST_RADON_ELIMINAR: 'Eliminar Sol·licitud',
        TEST_RADON_HOME_TITULO: 'Selecciona el tipus de Test Radó que vols sol·licitar',
        TEST_RADON_BASIC_TITULO: 'Test Radó <br/> Bàsic',
        TEST_RADON_BASIC_DESCRIPCION: 'El Test per conèixer el nivell de concentració de radó del meu habitatge',
        TEST_RADON_BASIC_NOTA: 'Nota: aqui',
        TEST_RADON_PROD_TITULO: 'Test Radó Professional (justificació CTE DB HS6)',
        TEST_RADON_PROD_DESCRIPCION: "El Test per a professionals que hagin de verificar i justificar el compliment de l'exigència del CTE DB HS6",
        TEST_RADON_PROD_NOTA: 'Nota: Únicament permet la sol·licitud del mesurament de llarga durada. Segons el fabricant es recomana que els detectors romanguin exposats un període mínim de 3 mesos',
        TEST_RADON_HOME_SELECCIONAR: 'Seleccionar',
        TEST_RADON_TIPO_INTERVENCION: "Tipus d'intervenció",
        TEST_RADON_TIPO_AMPLIACION: "Tipus d'ampliació",
        TEST_RADON_TIPO_MEDICION: 'Tipus de mesura que desitges realitzar',
        TEST_RADON_DATOS_EDIFICIO: "Dades de l'habitatge/edifici a mesurar:",
        TEST_RADON_TIPO_EDIFICIO: "Tipologia d'edifici *",
        TEST_RADON_PISO: 'Pis *',
        TEST_RADON_OTROS: "Indica la tipologia d'edifici",
        TEST_RADON_DATOS_PROPIETARIO: 'Dades propietari',
        TEST_RADON_TIPO_PERSONA: 'Tipus de persona *',
        TEST_RADON_RAZON_SOCIAL: 'Raó social *',
        TEST_RADON_NOMBRE: 'Nom *',
        TEST_RADON_APELLIDO: 'Cognom *',
        TEST_RADON_CORREO: 'Correu electrònic *',
        TEST_RADON_TELEFONO: 'Telèfon *',
        TEST_RADON_ACTUA: 'Actua com:',
        TEST_RADON_TITULO: 'Títol:',
        TEST_RADON_NUMERO_COLEGIADO: 'Número de col·legiat:',
        TEST_RADON_ENTIDAD: "Nom de l'Entitat:",
        TEST_RADON_ANOTACIONES: 'ANOTACIONS (pots indicar qualsevol dada no especificada als camps anteriors)',
        TEST_RADON_UBICACION_EDIFICIO: "Ubicació de l'habitatge/edifici a mesurar:",
        TEST_RADON_DIRECCION: 'Direcció *',
        TEST_RADON_PUERTA: 'Porta',
        TEST_RADON_NUMERO : 'Número *',
        TEST_RADON_CODIGO_POSTAL: 'Codi postal',
        TEST_RADON_MUNICIPIO: 'Municipi:',
        TEST_RADON_ANIO_CONTRUCCION: 'Any de construcció',
        TEST_RADON_REFORMA_INDICA: 'Indica tipus de reforma i any',
        TEST_RADON_REFORMA_PREVIAS: "Reformes prèvies de l'edifici",
        TEST_RADON_ZONA: 'Zona',
        TEST_RADON_LOCALIZAR: 'Localitzar',
        TEST_RADON_SUPERFICIE: 'Superfície útil',
        TEST_RADON_DIRECCION_DATOS_PETICIONARIO: 'Dades peticionari',
        TEST_RADON_DIRECCION_DATOS_EDIFICIO: "Dades de l'habitatge/edifici a mesurar",
        TEST_RADON_DIRECCION_DATOS_OTROS: 'Una altra',
        TEST_RADON_DETALLE_DETECTORES: "Enviament de detectors: Indiqueu l'adreça on voleu rebre els detectors",
        TEST_RADON_DETERMINACION: 'Determinació de nom de detectors:',
        TEST_RADON_ACREDITADOR: 'La persona que acredita el compliment dels següents dades és:',
        TEST_RADON_CONTINUAR: 'Continuar',
        TEST_RADON_BORRADOR: 'Desa com a esborrany',
        TEST_RADON_VOLVER: 'Tornar',
        TEST_RADON_AGREGAR_PLANTA: 'Afegir planta',
        TEST_RADON_DISPONE_LOCAL: 'Local habitable',
        TEST_RADON_LOCALE_PLANTA: 'Locals habitables de la planta',
        TEST_RADON_DISPONE_LOCAL_FORM: 'Local habitable',
        TEST_RADON_METRO_PLANTA: 'M2 per planta',
        TEST_RADON_ALCALA_PLANTA: 'Alçada planta (m)',
        TEST_RADON_TIPOLOGIA_PLANTA: 'Tipologia de planta',
        TEST_RADON_NUMERO_DETECTOR: 'N° detectors',
        TEST_RADON_LOADING: 'Carregant esperi si us plau...',
        TEST_RADON_NO_RESULTADO: 'No hi ha resultats per mostrar',
        TEST_RADON_IDENTIFIQUE_USO_FORM: "Identifiqueu-ne l'ús (p. exemple: aparcament, traster, etc.).",
        TEST_RADON_LOCALES_HABITABLE: "Locals Habitables (p. exemple: sala d'estar, dormitori, bany, despatx, cuina).",
        TEST_RADON_M_PLANTA_FORM:'M2 superfície útil planta *',
        TEST_RADON_ALTURA_PLANTA_FORM: 'Alçada planta (m) *',
        TEST_RADON_MODAL_BTN_CERRAR: 'Tancar',
        TEST_RADON_TOTAL_SENSORES: 'Total detectors',
        TEST_RADON_TOTAL_EXTRA_SENSORES: 'Addicional',
        TEST_RADON_TOTAL_PROFESIONAL_SENSORES: 'Total de sensors professionals:',
        TEST_RADON_FACTURACION: 'Dates de facturació',
        TEST_RADON_PRECIO_UNITARIO: 'Preu unitari per detector (Iva inclòs)',
        TEST_RADON_TOTAL_IMPORTE: 'Import total a pagar:',
        TEST_RADON_METODO_PAGO: 'Seleccioneu un mètode de pagament:',
        TEST_RADON_CAMBIAR_PAGADOR: 'Vull canviar el pagador de la factura',
        TEST_RADON_CAMBIAR_PAGADOR_INFO: 'Si canvies el pagador a empresa, has de marcar pagament en visa. Només es pot fer domiciliació als col·legiats',
        TEST_RADON_PAGO_CORRECTO: 'Sol·licitud pagada correctament',
        TEST_RADON_MEDICION_TITULO: 'Detall Sensors',
        TEST_RADON_MEDICION_TABLE_PLANTA: 'Planta',
        TEST_RADON_MEDICION_TABLE_NUMERO_SENSOR: 'N° Sensor',
        TEST_RADON_MEDICION_TABLE_FECHA_DESDE: 'Data des',
        TEST_RADON_MEDICION_TABLE_FECHA_HASTA: 'Data fins',
        TEST_RADON_MEDICION_TABLE_NOTA: 'Nota',
        TEST_RADON_SINCRONIZAR: 'Sincronitzar a Radonova',
        TEST_RADON_RECIBIDO_DETECTORES: 'He rebut els detectors',
        TEST_RADON_ENVIO_LABORATORIO: 'Enviar a laboratori',
        TEST_RADON_PENDIENTE_ANALISIS: 'Els detectors estan en procés danàlisi',
        TEST_RADON_SENSOR_FORM_TITULO: 'Editar Detector',
        TEST_RADON_SENSOR_FORM_PLANTAS: 'Plantes *',
        TEST_RADON_SENSOR_FORM_TIPO_PLANTA: 'Tipus de planta *',
        TEST_RADON_SENSOR_FORM_NUMERO_DETECTOR: 'N° Detector *',
        TEST_RADON_SENSOR_FORM_FECHA_INICIO: 'Data inici mesurament *',
        TEST_RADON_SENSOR_FORM_FECHA_FIN: 'Data fi mesurament *',
        TEST_RADON_SENSOR_FORM_UBICACION: 'Ubicació: *',
        TEST_RADON_SENSOR_FORM_NO_UTILIZADO: 'No utilitzat',
        TEST_RADON_SENSOR_BTN_EDITAR: 'Edita',
        TEST_RADON_ESTADO_TITULO: 'Estàs segur de confirmar aquesta acció?',
        TEST_RADON_ESTADO_CONFIRMAR: 'Sí, Confirmar',
        TEST_RADON_CONFIRMAR_BORRADOR: 'Segur que voleu eliminar la sol·licitud?',
        TEST_RADON_BORRADOR_BTN: 'Sí, eliminar',
        TEST_RADON_SOLUCIONES_TITULO: 'Solucions implementades per a la protecció davant del radó:',
        TEST_RADON_MOTIVO_MEDIACION: 'Motiu del mesurament:',
        TEST_RADON_FILTRO_TIPO_TEST: 'Tipus de Test:',
        TEST_RADON_FILTRO_TIPO_EDIFICIO: "Tipologia d'edifici a mesurar:",
        TEST_RADON_FILTRO_PROVINCIA: 'Província:',
        TEST_RADON_FILTRO_MUNICIPIO: 'Municipi:',
        TEST_RADON_FILTRO_ZONA: 'Zona:',
        TEST_RADON_FILTRO_NUMERO_DETECTOR: 'Número de detector:',
        TEST_RADON_FILTRO_NUMERO_SOLICITUD: 'N° Sol·licitud:',
        TEST_RADON_FILTRO_ESTADO: 'Estat:',
        TEST_RADON_FILTRO_DESDE: 'Data des de:',
        TEST_RADON_FILTRO_HASTA: 'Data fins:',
        TEST_RADON_FILTRO_PERSONA: 'Persona:',
        TEST_RADON_TABLE_NUMERO_SOLICITUD: 'N° Sol·licitud',
        TEST_RADON_TABLE_DIRECCION: 'Direcció',
        TEST_RADON_TABLE_TOTAL_DETECTORES: 'Total detectors',
        TEST_RADON_TABLE_ESTADO: 'Estat',
        TEST_RADON_TABLE_FECHA_ESTADO: 'Data estat',
        TEST_RADON_TABLE_NO_REGISTROS: 'No hi ha sol·licituds per mostrar',
        TEST_RADON_DESCARGAR_INFORME: 'Descarregar resultat Test Radó',
        TEST_RADON_RESULTADO_SOLICITUD:'Resultado final de la solicitud',
        TEST_RADON_ANALISIS_OK: 'Anàlisi finalitzat correctament',
        HOME_CONTACTO: 'Contacte',
        HOME_HEADER_TITULO: 'Test Radó',
        HOME_HEADER_SUB_TITULO: 'Potser no el veus, però hi pot ser',
        HOME_HEADER_TEXTO_1: 'El radó és un gas radioactiu que no es pot veure ni olorar i l’exposició durant un llarg període de temps en espais interiors és perjudicial per a la salut. Per lo tant l’única forma de saber si s’hi està exposat, és mesurar la seva concentració en l’aire.',
        HOME_HEADER_TEXTO_: 'El Test Radó és un mètode de mesurament a l’abast dels',
        HOME_HEADER_TEXTO__: 'que vulguin donar compliment reglamentari als seus projectes d’edificació i posar a disposició de la',
        HOME_HEADER_TEXTO___: "l’opció de conèixer la concentració de radó al seu edifici.",
        HOME_HEADER_TEXTO_SPAN1: "professionals",
        HOME_HEADER_TEXTO_SPAN2: "ciutadania",
        HOME_HEADER_INFO_USUARIO: "Informació prèvia per als usuaris",
        HOME_BTN_SABER_MAS: 'Saber més',
        HOME_BTN_LOGIN: 'Iniciar sessió',
        HOME_BTN_REGISTRO: 'Registrar-se',
        HOME_TARIFA_DATALLE_1: '* Els preus indicats tenen l’IVA inclòs.',
        HOME_TARIFA_DATALLE_2: '* El preu indicats correspon inclouen l’enviament de detectors a la península i a les illes balears. Els enviaments a les illes canàries tenen un cost addicional de 30 euros.',
        HOME_TARIFA_TABLA_TITULO: 'Veure taula de preus [+]',
        INFO_USUARIO_TITULO: 'INFORMACIÓ PRÈVIA PER ALS USUARIS',
        INFO_USUARIO_SUBTITULO: 'Per a iniciar la sol·licitud del Test Radó només cal seguir aquests passos:',
        INFO_USUARIO_PASO1: 'Pas 1:',
        INFO_USUARIO_PASO1_TEXT: 'Abans d’iniciar la seva sol·licitud, consulti tota la informació relacionada amb el servei que vol contractar.',
        INFO_USUARIO_PASO1_DETALLE1: "Els col·legiats del Col·legi de l'Arquitectura Tècnica de Barcelona poden accedir amb el vostre usuari i contrasenya personal.",
        INFO_USUARIO_PASO1_DETALLE2: "Els col·legiats que s'integren dins del Consell General de l'Arquitectura Tècnica d'Espanya poden accedir amb l'usuari i la contrasenya d'accés a la finestreta única del CGATE.",
        INFO_USUARIO_PASO1_DETALLE3: "La resta d'usuaris s'han de registrar a la plataforma.",
        INFO_USUARIO_PASO2: 'Pas 2:',
        INFO_USUARIO_PASO2_TEXT: `Per a poder gestionar la seva sol·licitud, és necessari formalitzar un registre d’usuari. (Si és un col·legiat Cateb, no cal registrar-se. Pot iniciar sessió amb la seva contrasenya col·legial).`,
        INFO_USUARIO_PASO3: 'Pas 3:',
        INFO_USUARIO_PASO3_TEXT: 'Iniciar sessió amb el seu compte d’usuari i seleccionar el servei de Test Radó que vol contractar. Caldrà complimentar totes les dades de la sol·licitud i fer el pagament.',
        INFO_USUARIO_PASO4: 'Pas 4:',
        INFO_USUARIO_PASO4_TEXT: "Un cop fet el pagament, iniciarà el procediment automàtic d'enviament dels detectors a l'adreça indicada, a través del nostre servei de missatgeria : UPS.",
        INFO_USUARIO_PASO5: 'Pas 5:',
        INFO_USUARIO_PASO5_TEXT: 'Activar els detectors. Accedeixi amb el seu perfil d’usuari a la seva sol·licitud i confirmi la recepció dels detectors per a descarregar les “Instruccions de col·locació i posada en marxa dels detectors”.',
        INFO_USUARIO_PASO6: 'Pas 6:',
        INFO_USUARIO_PASO6_TEXT: 'Desactivar els detectors. Accedeixi amb el seu perfil d’usuari a la seva sol·licitud i confirmi la data de final de mesurament per a poder enviar els detectors al laboratori.',
        INFO_USUARIO_PASO7: 'Pas 7:',
        INFO_USUARIO_PASO7_TEXT: 'Retorn dels detectors. Cal enviar tots els detectors seguint les instruccions de devolució al nostre centre de recepció del Cateb: C/Bon Pastor, núm. 5, planta 5ª. 08021 Barcelona indicant al sobre el número de la seva sol·licitud.',
        INFO_USUARIO_PASO8: 'Pas 8:',
        INFO_USUARIO_PASO8_TEXT: 'Descarregar el resultat del Test Radó que tindrà disponible al final del procés de la seva sol·licitud.',
        HOME_BTN_CERRAR: 'Tancar',
        HOME_LOGIN_TITLE: 'Ingrés al sistema',
        HOME_LOGIN_BTN: 'Iniciar Sessió',
        HOME_LOGIN_RECUPERAR_CONTRASENIA: 'Recuperar contrasenya',
        HOME_REGISTER_TITLE: 'Crear un compte nou',
        HOME_REGISTER_ES_COLEGIADO: 'Si és un col·legiat Cateb, no cal registrar-se. Pot iniciar sessió amb la seva contrasenya col·legial',
        HOME_BTN_CONTINUAR: 'Continuar',
        HOME_BTN_ATRAS: 'Tornar',
        HOME_BTN_REGISTRAR: 'Registrar usuari',
        HOME_BTN_REGISTRAR_TAB1: 'Escriviu el vostre NIF / NIE',
        HOME_BTN_REGISTRAR_TAB2: 'Dades Generals',
        HOME_REGISTER_DATOS_PERSONALES: 'Dades personals',
        HOME_REGISTER_DATOS_DIRECCION: 'Dades Adreça',
        HOME_REGISTER_DATOS_ACCESO: "Dades d'accés",
        HOME_REGISTER_INPUT_DOCUMENTO: 'Document *',
        HOME_REGISTER_INPUT_NOMBRE: 'Nom *',
        HOME_REGISTER_INPUT_APELLIDO: 'Cognoms *',
        HOME_REGISTER_INPUT_TIPO_VIA: 'Tipus via *',
        HOME_REGISTER_INPUT_DIRECCION: 'Adreça *',
        HOME_REGISTER_INPUT_NUMERO: 'Número *',
        HOME_REGISTER_INPUT_PISO: 'Pis',
        HOME_REGISTER_INPUT_PUERTA: 'Porta',
        HOME_REGISTER_INPUT_ESCALERA: 'Escala',
        HOME_REGISTER_INPUT_COD_POSTAL: 'Codi Postal *',
        HOME_REGISTER_INPUT_POBLACION: 'Població *',
        HOME_REGISTER_INPUT_TELEFONO: 'Telèfon de contacte *',
        HOME_REGISTER_INPUT_EMAIL: 'Email *',
        HOME_REGISTER_INPUT_PASSWORD: 'Contrasenya *',
        HOME_REGISTER_INPUT_CCPASSWORD: 'Repetir contrasenya *',
        HOME_REGISTER_NOTA: 'Els camps amb un * són obligatoris.',
        HOME_SECTION1_TITLE: 'Els nostres serveis:',
        HOME_SECTION1_BOX1_TITLE: 'USUARIS',
        HOME_SECTION1_BOX2_TITLE: 'PROFESSIONALS',
        HOME_SECTION1_BOX1_SUBTITLE: 'Test Radó Bàsic',
        HOME_SECTION1_BOX2_SUBTITLE: 'Test Radó Professional-justificació CTE',
        HOME_SECTION1_BOX1_CONTENIDO: 'Pensat per a qualsevol persona que vulgui conèixer els nivells de radó a què està exposat.',
        HOME_SECTION1_BOX2_CONTENIDO: 'Pensat per a professionals del sector, que com a projectista, direcció facultativa o entitat de control vol mesurar la concentració de radó segons les exigències del CTE DB HS6.',
        HOME_CONTACTO_TITLE: "Contacta'ns",
        HOME_CONTACTO_INPUT_NAME: 'Nom *',
        HOME_CONTACTO_INPUT_EMAIL: 'Email *',
        HOME_CONTACTO_INPUT_ASUNTO: 'Assumpte *',
        HOME_CONTACTO_INPUT_MENSAJE: 'Missatge *',
        HOME_CONTACTO_BTN: 'Enviar',

        HOME_PASSWORD_TITLE: 'Recuperar contrasenya',
        HOME_PASSWORD_BTN: 'Recuperar',
        HOME_PASSWORD_INFO: 'Si és un col·legiat Cateb, ha de restablir la contrasenya a',
        HOME_PASSWORD_ENLACE: 'el següent enllaç.',

        HOME_RADON_TITLE: 'Test Radó',
        HOME_RADON_INFO: 'Potser no el veus, però hi pot ser',
        HOME_RADON_TEXT1: 'Amb quins aparells faré el mesurament?',
        HOME_RADON_DESCRIPCION1: 'El mesurament es realitza mitjançant detectors passius de traces alfa. Aquest aparells tenen dimensions molt reduïdes i autonomia durant tot el període d’exposició, sense necessitat de fonts d’alimentació (endolls).',
        HOME_RADON_TEXT2: 'Quin resultat obtindré?',
        HOME_RADON_DESCRIPCION2: 'La concentració mitja de radó',
        HOME_RADON_DESCRIPCION3: 'durant el període d’exposició dels detectors.',

        HOME_RADON_BASICO_TITLE: 'Test Radó Bàsic',
        HOME_RADON_BASICO_TEXT: 'Pensat per a qualsevol persona que vulgui conèixer els nivells de radó a què està exposat.',
        HOME_RADON_BASICO_TEXT_INFO1: 'El Test Radó Bàsic s’ofereix en dos formats:',
        HOME_RADON_BASICO_TEXT_SPAN1: 'Mesurament curt:',
        HOME_RADON_BASICO_TEXT_INFO2: 'podràs tenir una aproximació dels valors de radó obtinguts durant un període d’entre 5-10 dies.',
        HOME_RADON_BASICO_TEXT_SPAN2: 'Mesurament llarg:',
        HOME_RADON_BASICO_TEXT_INFO22: 'podràs obtenir una mesura fiable dels nivells de radó al teu edifici durant un període d’exposició d’un any (mínim intervals de 3 mesos).',
        
        HOME_RADON_BASICO_TARIFA: 'Tarifes',
        HOME_RADON_BASICO_BOX1_TITLE: 'Mesurament curt',
        HOME_RADON_BASICO_BOX1_INFO: '<span>Tarifa general</span> <br/> <div>A partir de 125 €</div>',
        HOME_RADON_BASICO_BOX1_INFO2: "<span>Tarifa per a Arquitectes Tècnics col·legiats a Catalunya</span> <br/>  <div>A partir de 110 €</div>",

        HOME_RADON_BASICO_BOX2_TITLE: 'Mesurament llarg', //(recomanada)
        HOME_RADON_BASICO_BOX2_INFO: '<span>Tarifa general</span> <br/> <div>A partir de 115 €</div>',
        HOME_RADON_BASICO_BOX2_INFO2: '<span>Tarifa per a Arquitectes Tècnics col·legiats a Catalunya</span> <br/> <div>A partir de 100 €</div>',
        HOME_RADON_BASICO_CONDICIONES: 'Condicions:',
        HOME_RADON_BASICO_CONDICIONES_INFO1: 'Els usuaris amb conveni es validen en el moment de la compra.',
        HOME_RADON_BASICO_CONDICIONES_INFO2: 'Els preus varíen en funció del nombre de detectors necessaris per assegurar la medició. S’aplicarà un cost addicional per enviaments a les Illes Canàries. (el preu indicat correspon a la sol·licitud mínima de 2 detectors a la península i balears).', 
        HOME_RADON_BASICO_SERVICIO: 'El servei Test Radó Bàsic inclou:',  
        HOME_RADON_BASICO_SERVICIO_INFO1: 'Accés gratuït a la plataforma Test Radó on tindràs disponible el seguiment i resultat de la teva prova, així com informació i assessorament personalitzat en cas necessari.',
        HOME_RADON_BASICO_SERVICIO_INFO2: 'Enviament per missatgeria dels detectors (Companyia UPS). El nombre de detectors es determinarà en funció de les zones de mostreig sota la justificació del projectista, direcció facultativa o entitat de control. (NOTA: El preu indicat correspon a la sol·licitud mínima de 2 detectors a la península i illes balears. Els enviaments a les illes canàries tenen un cost addicional).',
        HOME_RADON_BASICO_SERVICIO_INFO3: 'Instruccions de col·locació i devolució dels detectors disponibles en tot moment a la plataforma.',
        HOME_RADON_BASICO_SERVICIO_INFO4: 'Resultat del Test en pdf amb els valors mesurats de la concentració mitja de radó durant el període d’exposició. Amb aquests valors, el projectista, direcció facultativa o entitat de control, podrà fer l’estimació de la mitjana anual de concentració de radó per a la justificació de les exigències previstes en el CTE DB HS6.',
        HOME_RADON_BASICO_SERVICIO_INFO5: 'Contacte de suport a l’usuari.',  
        HOME_RADON_BASICO_SERVICIO_INFO6: 'Accés a la borsa d’arquitectes tècnics del Cateb per a l’estudi i proposta de mesures de mitigació en cas necessari.',  

        HOME_RADON_BASICO_SERVICIO_NO_INCLUYE: 'El servei Test Radó Bàsic NO inclou:',  
        HOME_RADON_BASICO_SERVICIO_NO_INCLUYE_INFO1: 'Retorn dels detectors al centre de recepció del Cateb: C/Bon Pastor, núm. 5, planta 5ª. 08021 Barcelona.', 

        HOME_RADON_PROFESIONAL_TITLE: 'Test Radó Professional-justificació CTE',
        HOME_RADON_PROFESIONAL_TEXT: 'Pensat per a professionals del sector, que com a projectista, direcció facultativa o entitat de control volen mesurar la concenrtració de radó segons les exigències del CTE DB HS6.',
        HOME_RADON_PROFESIONAL_INFO: 'El Test Professional només està disponible per a mesuraments de 3 mesos, per tant només és possible la sol.licitud de',
        HOME_RADON_PROFESIONAL_INFO_SPAN: 'Mesurament llarg.',
        HOME_RADON_PROFESIONAL_TARIFA: 'Tarifes',
        HOME_RADON_PROFESIONAL_BOX_TITLE: 'Mesurament llarg',
        HOME_RADON_PROFESIONAL_BOX_INFO1: '<span>Tarifa general</span> <br/> <div>A partir de 115 €</div>',
        HOME_RADON_PROFESIONAL_BOX_INFO2: '<span>Tarifa per a Arquitectes Tècnics col·legiats a Catalunya</span> <br/> <div>A partir de 100 €</div>',
        HOME_RADON_PROFESIONAL_CONDICIONES: 'Condicions',
        HOME_RADON_PROFESIONAL_CONDICIONES_INFO1: 'Els usuaris amb conveni es validen en el moment de la compra.',
        HOME_RADON_PROFESIONAL_CONDICIONES_INFO2: 'Els preus varíen en funció del nombre de detectors necessaris per assegurar la medició. S’aplicarà un cost addicional per enviaments a les Illes Canàries. (el preu indicat correspon a la sol·licitud mínima de 2 detectors a la península i balears).',
        HOME_RADON_PROFESIONAL_SERVICIO: 'El servei Test Radó Professional inclou:',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO1: 'Accés gratuït a la plataforma Test Radó on tindràs disponible el seguiment i resultat de la teva prova, així com informació i assessorament personalitzat en cas necessari.',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO2: 'Enviament per missatgeria dels detectors (Companyia UPS). El nombre de detectors es determinarà en funció de les zones de mostreig sota la justificació del projectista, direcció facultativa o entitat de control. (NOTA: El preu indicat correspon a la sol·licitud mínima de 2 detectors a la península i illes balears. Els enviaments a les illes canàries tenen un cost addicional).',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO3: 'Instruccions de col·locació i devolució dels detectors disponibles en totmoment a la plataforma.',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO4: 'Resultat del Test en pdf amb els valors mesurats de la concentració mitja de radó durant el període d’exposició. Amb aquests valors, el projectista, direcció facultativa o entitat de control, podrà fer l’estimació de la mitjana anual de concentració de radó per a la justificació de les exigències previstes en el CTE DB HS6.',
        HOME_RADON_PROFESIONAL_SERVICIO_INFO5: 'Contacte de suport a l’usuari.',

        HOME_RADON_PROFESIONAL_SERVICIO_NO_INCLUYE: 'El servei Test Radó Professional NO inclou:',
        HOME_RADON_PROFESIONAL_SERVICIO_NO_INCLUYE_INFO1: 'Retorn dels detectors al centre de recepció del Cateb: C/Bon Pastor, núm. 5, planta 5ª. 08021 Barcelona.',

        RADON_MODAL_PAGADOR_TITLE: 'Vull canviar el pagador de la factura',
        RADON_MODAL_PAGADOR_OPTION1: 'Les meves dades personals',
        RADON_MODAL_PAGADOR_OPTION2: 'En una empresa',
        RADON_MODAL_PAGADOR_OPTION3: 'A una persona',
        RADON_MODAL_PAGADOR_GUARDAR_PAGADOR: 'Desar',
        RADON_MODAL_PAGADOR_INPUT_PAGADOR: 'Pagador *',

        RADON_MODAL_CAMBIAR_PAGADOR_TITLE: 'Dades del pagador',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PAGADOR: 'Document *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_NOMBRE: 'Nom  *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_APELLIDO: 'Cognoms  *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_RAZON_SOCIAL: 'Raó social *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_TIPO_VIA: 'Tipus via *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_DIRECCION: 'Adreça *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_NUMERO: 'Número *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PISO: 'Pis',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_PUERTA: 'Porta',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_ESCALERA: 'Escala',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_CODIGO_POSTAL: 'Codi Postal *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_POBLACION: 'Població *',
        RADON_MODAL_CAMBIAR_PAGADOR_INPUT_EMAIL: 'Email *',
        RADON_MODAL_CAMBIAR_PAGADOR_SALIR: 'Sortir',
        RADON_MODAL_CAMBIAR_PAGADOR_ACEPTAR: 'Acceptar',

        // DOCUMENTO_INVALIDO: 'Introduïu un NIF, CIF o NIE vàlid.',
        DOCUMENTO_INVALIDO: 'Introduïu un document vàlid.',

        DOCUMENTO_CORRECTO: 'Document vàlid.',
        LOGIN_CORRECTO: 'Login iniciat correctament',

        SIDEBAR_MENU1: 'Test radó nou',
        SIDEBAR_MENU2: 'Test radó',
        SIDEBAR_MENU3: 'Les meves dades',
        P_TEST_RADON_PROFESIONAL_JUSTIFICACION_CTE: `Test Radó Professional-justificació CTE`,
        P_RESULTADO_TEST_1: `Resultat del Test en pdf amb els valors mesurats de la concentració mitja de radó durant el període d’exposició.`,
        P_RESULTADO_TEST_2: `Resultat del Test en pdf amb els valors mesurats de la concentració mitja de radó durant el període d’exposició. Amb aquests valors, el projectista, direcció facultativa o entitat de control, podrà fer l’estimació del promig anual de concentració de radó per a la justificació de les exigències previstes en el CTE DB HS6.`,
        OCULTAR: 'Amagar',
        DATOS_PERSONALES: `Dades personals`,
        NOMBRE: `Nom`,
        APELLIDOS: `Cognoms`,
        MOBIL: 'Mòbil',
        DATOS_DIRECCION: `Dades Adreça`,
        TIPO_VIA: `Tipus de via`,
        DIRECCION: `Adreça`,
        NUMERO: `Número`,
        PISO: `Pis`,
        PUERTA: `Porta`,
        ESCALERA: `Escala`,
        CODIGO_POSTAL: `Codi postal`,
        POBLACION: `Població`,
        P_MODIFICAR_DATOS_CONTACTO_CATEB: `Per modificar les vostres dades, poseu-vos en contacte amb l'Administrador de Test Radó.`,
        PRIVACIDAD_COOKIES: `Privadesa i Cookies`,
        AREA_ADMINISTRACION: `Àrea d'Administració`,
        ACCIONES: `Accions`,
        VER_SOLICITUD: `Veure sol·licitud`,
        SOLICITUDES: `Sol·licituds`,
        BORRADOR: `Esborrany`,
        PAGADO: `Pagat`,
        SOLICITADO: `Sol·licitat`,
        MEDICION: `Mesurament`,
        PENDIENTE_ANALISIS: `Pendent d'anàlisi`,
        FILTROS: `Filtres`,
        P_SEGURO_ELIMINAR_PLANTA: `Esteu segur que voleu eliminar la planta?`,
        SI_ELIMINAR: `Sí, eliminar`,
        P_PLANTA_PLANTA_BAJA_PLANTA_1: `Planta: (Planta Baixa, Planta -1, Planta 3)`,
        P_DISPONE_LOCALES_HABITABLES: `Disposa de locals habitables?`,
        IDENTIFIQUE_USO: `Identifiqueu-ne l'ús`,
        LOCALES_HABITABLES: `Locals Habitables`,
        P_SALA_DORMITORIO_COCINA: `Sala d'estar, Dormitori, Bany, Despatx, Cuina`,
        PLANTA_HABITABLES: `planta habitables`,
        ALTURA_PLANTA: `Alçada planta`,
        COMPARTIMENTADO: `Compartimentat`,
        SIN_COMPARTIMENTAR: `Sense compartimentar`,
        EDITAR: `Editar`,
        ACEPTAR: `Acceptar`,
        CONTINUAR: `Continuar`,
        DETERMINACION_NUMERO_DETECTORES: `Determinació de nombre de detectors`,
        P_IDENTIFICA_PLANTA: `Identifica la planta. Exemple: Planta soterrani -1, planta baixa, planta 1a`,
        P_EJEMPLO_RECINTOS_TRABAJO_AULAS: `p. exemple: recintes de treball o oberts al públic com aules, biblioteques, habitacions hospitalàries, despatxos, sales d'espera o de reunions, etc.`,
        SELECCIONE: `Seleccioneu`,
        DATOS_FACTURACION: `Dades de facturació`,
        TOTAL_SOLICITUD_DETECTORES: `Total de la sol·licitud de detectors`,
        RAZON_SOCIAL: `Raó social`,
        N_DETECTOR: `N° Detector`,
        UBICACION_DETECTOR: `Ubicació Detector`,
        REGISTRAR_INFORMACION: `Registrar informació`,
        P_DETECTORES_PROCESO_ANALISIS: `Els detectors són en processos d'anàlisi`,
        ENVIAR_A_LABORATORIO: `Enviar a laboratori`,
        P_RECIBIDO_DETECTORES: `He rebut els detectors`,
        P_DESCARGAR_INFORME_RESULTADOS: `Descarregar Informe de resultats`,
        DEVOLUCION_DETECTORES: `Devolució de detectors`,
        PLANOS: `Plànols`,
        AGREGAR_PLANO: `Afegir plànol`,
        ELIMINAR_PLANO: `Eliminar plànol`,
        FICHERO_ADJUNTO: `Fitxer adjunt`,
        PLANO: `Plànol`,
        ELIMINAR: `Eliminar`,
        EL_CAMPO_ES_OBLIGATORIO: `El camp és obligatori`,
        TRANSPORTE: `Transport`,
        SENSORES_ADICIONALES: `Sensors addicionals`,
        PRECIO_SENSORES_ADICIONALES: `Preu total sensors addicionals`,
        PAGAR: `Pagar`,
        PROVINCIA: `Província`,
        MUNICIPIO: `Municipi`,
        SOLICITUD_TEST_RADON: `Sol·licitud Test Radó`,
        SUPERFICIE_UTIL: `Superfície Útil`,
        P_QUIERES_ANADIR_DETECTOR: `Vols afegir-hi algun detector més?`,
        GUARDAR_COMO_BORRADOR: `Desa com a esborrany`,
        ENVIO_A_CANARIAS: `Enviament a Canàries`,
        DESCARGAR_INSTRUCCIONES: `Descarregar instruccions`,
        DESCARGAR_ANEXO: `Descarregar annex`,
        RESULTADO: `Resultat`,
        P_CONTINUAR_NECESARIO_ALMENOS_1_SENSOR: `Per continuar, hi ha d'haver almenys 1 detector`,
        P_GESTIONAR_PLANOS_CLICK_AQUI: `Per gestionar els vostres plànols, feu clic aquí`,
        CONDICIONES_CONTRATACION: `Condicions de contractació`,
        P_MANIFIESTO_LEIDO_ACEPTO: `Manifesto que he llegit i accepto les`,
        RECHAZAR: `Rebutjar`,
        P_CONTACTAR_CON_NOSOTROS: `Si vols contactar amb nosaltres ho pots fer mitjançant el telèfon o escrivint al correu electrònic`,
        ACCESO: `Accés`,
        APARTAMENTOS: `Apartaments`,
        AUTOPISTA: `Autopista`,
        AUTOVIA: `Autovia`,
        AVENIDA: `Avinguda`,
        BAJADA: `Baixada`,
        BARRANCO: `Barranc`,
        BARRIO: `Barri`,
        BLOQUE: `Blocs`,
        CALZADA: `Calçada`,
        CAMINO: `Camí`,
        CALLE: `Carrer`,
        CALLEJON: `Carreró`,
        CARRETERA: `Carretera`,
        CASAS: `Cases`,
        CIRCUNVALACION: `Circumval·lació`,
        COLONIA: `Colònia`,
        COMPLEJO: `Complex`,
        CORREDOR: `Corredor`,
        CORRIOL: `Corriol`,
        COSTA: `Costa`,
        ATAJO: `Drecera`,
        EJE: `Eix`,
        GLORIETA: `Glorieta`,
        GRAN_VIA: `Gran Via`,
        GRUPO: `Grup`,
        ISLA: `Illa`,
        JARDINES: `Jardins`,
        LUGAR: `Lloc`,
        MIRADOR: `Mirador`,
        MOLL: `Moll`,
        MURALLA: `Muralla`,
        PARAJE: `Paratge`,
        PARQUE: `Parc`,
        PARTIDA: `Partida`,
        PASADILLO: `Passadís`,
        PASAJE: `Passatge`,
        PISTA: `Pista`,
        PLAZA: `Plaça`,
        PLACETA: `Placeta`,
        PLAYA: `Platja`,
        POLIGONO: `Polígon`,
        POLIGONO_INDUSTRIAL: `Polígon industrial`,
        PUENTE: `Pont`,
        PUERTO: `Port`,
        PORTAL: `Portal`,
        PROLONGACION: `Prolongació`,
        SUBIDA: `Pujada`,
        RINCON: `Racó`,
        RAMBLA: `Rambla`,
        RAVAL: `Raval`,
        RIAL: `Rial`,
        RIERA: `Riera`,
        RONDA: `Ronda`,
        ROTONDA: `Rotonda`,
        SECTOR: `Sector`,
        TRAVESIA: `Trauèssa`,
        TRAVESERA: `Travessera`,
        URBANIZACION: `Urbanització`,
        VECINDARIO: `Veïnat`,
        VIA: `Via`,
        VIADUCTO: `Viaducte`,
        VIAL: `Vial`,
        ZONA: `Zona`,
        DATOS_VIVIENDA_EDIFICIO: `Dades de l'habitatge/edifici`,
        V_OBLIGATORIO_NUMERO_COLEGIADO: `El número de col·legiat és obligatori.`,
        V_OBLIGATORIO_NOMBRE: `El nom és obligatori.`,
        V_OBLIGATORIO_TELEFONO: `El telèfon és obligatori.`,
        V_OBLIGATORIO_CONTRASENIA: `La contrasenya és obligatòria.`,
        V_OBLIGATORIO_DOCUMENTO: `El document és obligatori.`,
        V_OBLIGATORIO_CAMPO: `El camp és obligatori.`,
        V_OBLIGATORIO_CAMPO_NA: `El camp és obligatori. Indiqueu “N/A” si no s'aplica.`,
        V_OBLIGATORIO_CORREO: `El correu és obligatori.`,
        V_OBLIGATORIO_DIRECCION: `L'adreça és obligatòria.`,
        V_OBLIGATORIO_PROVINCIA: `La província és obligatòria.`,
        V_CONFIRMACION_OBLIGATORIO: `La confirmació de contrasenya és obligatòria.`,
        V_OBLIGATORIO_POBLACION: `La població és obligatòria.`,
        V_OBLIGATORIO_CODIGO_POSTAL: `El codi postal és obligatori.`,
        V_OBLIGATORIO_EMAIL: `L'email és obligatori.`,
        V_OBLIGATORIO_PAIS: `El país és obligatori.`,
        V_OBLIGATORIO_TIPO_DOCUMENTO: `El tipus de document és obligatori.`,
        V_OBLIGATORIO_MANIFIESTO: `El manifiesto és obligatori.`,
        V_OBLIGATORIO_APELLIDOS: `Els cognoms són obligatoris.`,
        V_OBLIGATORIO_NUMERO_DETECTOR: `El número de detector és obligatori.`,
        V_OBLIGATORIO_VIA_PAGO: `La via de pagament és obligatòria.`,
        CONTRASENIA_ANTIGUA: `Contrasenya antiga`,
        CONTRASENIA_NUEVA: `Contraseña nueva`,
        CONFIRMACION_CONTRASENIA_NUEVA: `Confirmació Contrasenya nova`,
        AGREGAR_SENSORES: `Afegir sensors`,
        CANT_SENSORES: `Cant. sensors`,
        P_USUARIO_CONTRASENIA_INVALIDOS: `Usuari o contrasenya incorrectes.`,
        P_DESCARGANDO_DOCUMENTO: `S'està baixant el document.`,
        EDIFICIO: `Edifici`,
        COPIAR_DATOS_PETICIONARIO: `Copiar dades del peticionari`,
        TIPO_DE_PROPIETARIO: `Tipus de propietari`,
        NUEVA_DIRECCION_ENVIO: `Nova Adreça d’enviament`,
        P_MANIFIESTO_INFORMACION_PROTECCION_DATOS: `MANIFIESTO que he llegit i ACCEPTO el contingut de la informació precedent relativa a la`,
        P_MANIFIESTO_INFORMACION_PROTECCION_DATOS_2: `protecció de dades de caràcter personal.`,
        p_MANIFIESTO_DATOS_TERCERAS_PERSONAS: `MANIFIESTO que he llegit i ACCEPTO que, si facilito les`,
        p_MANIFIESTO_DATOS_TERCERAS_PERSONAS_2: `dades a terceres persones, hauré d'informar i obtenir el seu consentiment previ en relació amb els aspectes relacionats en aquest document.`,
        P_MANIFIESTO_DATOS_PERSONALES_FACILITADOS: `MANIFIESTO que autoritzo que les meves dades personals siguin facilitades al Consell General de l'Arquitectura Tècnica d'Espanya, a l'Administració, etc. per a finalitats estadístiques i d'estudi sobre la situació i afectació del radó al nostre territori.`,
        P_CAMBIADO_CONTRASENIA_CORRECTAMENTE: `S'ha canviat la contrasenya correctament`,
        CAMBIAR: `Canviar`,
        CANCELAR: `Cancel·la`,
        TEST_RADON_PROFESIONAL: `Test Radó Professional`,
        P_MEDICION_DISPONIBLE_MESES: `Disponible per a mesuraments de mínim 3 mesos`,
        RECUPERAR_DATOS_PETICIONARIO: `Recuperar dades del peticionari`,
        PASEO: `Passeig`,
        NOMBRE_APELLIDOS: `Nom i cognoms`,
        EMAIL: `Email`,
        TELEFONO: `Telèfon`,
        PREGUNTAS_FRECUENTES: `Preguntes freqüents`,
        CONSULTA_COLEGIOS_CONVENIO: `Consulta els col·legis amb conveni`,
        EN_ESTE_ENLACE: `En aquest enllaç`,
        ATENCION_DE: 'A la atención de *',
        CONTACTAR: 'Contactar',
        P_ALERTA_HOME: `Us informem que del 5 al 23 d'agost de 2024 el servei de contacte romandrà tancat. Durant aquest període podeu utilitzar la plataforma amb normalitat i les consultes seran resoltes a partir del 23 d'agost.`,
        TARIFA_MODAL_TABLE_TITULO1: 'Núm. total detectors per sol·licitud',
        TARIFA_MODAL_TABLE_TITULO2: 'Tarifa General (en euros/IVA incl.)',
        TARIFA_MODAL_TABLE_TITULO3: 'Tarifa col·legiat (en euros/IVA incl.)',
        TARIFA_MODAL_TABLE_DETECTOR_ADICIONAL: 'Detector addicional'
        
    }
}

export default lang;