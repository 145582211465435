import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useModalData } from "hooks/useModalData";
import { NavHome } from './Nav'
import { HeaderHome } from './Header'
import { FooterHome } from './Footer'
import { ModalLogin } from './ModalLogin'
import { ModalPassword } from './ModalPassword'
import { ModalContacto } from './ModalContacto'
import { ModalRegistro } from './ModalRegistro'
import { ModalBasico } from './ModalBasico'
import { ModalProfesional } from './ModalProfesional'
import { ModalUsuarioInformacion } from './ModalUsuarioInformacion'
import { ModalInfoRadon } from './ModalInfoRadon'
import { useLang } from 'language';
import { ModalBasicoForm, ModalBasicoForm1, ModalBasicoForm2, ModalBasicoForm3 } from "./ModalBasicoForm";
import { ModalProfesionalForm, ModalProfesionalForm1, ModalProfesionalForm2, ModalProfesionalForm3 } from "./ModalProfesionalForm";
import { Footer } from "components/Footer/Footer";
import { AuthContext } from 'auth/AuthContext';
import { ModalTablaTarifas } from './ModalTablaTarifas'

export const Home = ({openModal}) => {
    const locale = useLang();
    const { user: { lang } } = useContext(AuthContext)
    const [dataModal_Login, handleOpenModal_Login, handleCloseModal_Login] = useModalData()
    const [dataModal_Contacto, handleOpenModal_Contacto, handleCloseModal_Contacto] = useModalData()
    const [dataModal_Registro, handleOpenModal_Registro, handleCloseModal_Registro] = useModalData()
    const [dataModal_Password, handleOpenModal_Password, handleCloseModal_Password] = useModalData()
    const [dataModal_Profesional, handleOpenModal_Profesional, handleCloseModal_Profesional] = useModalData()
    const [dataModal_Basico, handleOpenModal_Basico, handleCloseModal_Basico] = useModalData()
    const [dataModal_Info, handleOpenModal_Info, handleCloseModal_Info] = useModalData()
    const [dataModal_Radon, handleOpenModal_Radon, handleCloseModal_Radon] = useModalData()
    const [dataModal_Tarifa, handleOpenModal_Tarifa, handleCloseModal_Tarifa] = useModalData()

    useEffect(() => {
    }, [])

    const [showMore, setshowMore] = useState(false);


    return (
        <section className='PageIndex'>
            <NavHome
                handleOpenModal_Contacto={handleOpenModal_Contacto}
            />
            <HeaderHome
                handleOpenModal_Login={handleOpenModal_Login}
                handleOpenModal_Registro={handleOpenModal_Registro}
                handleOpenModal_Info={handleOpenModal_Info}
                handleOpenModal_Radon={handleOpenModal_Radon}
                openModal={openModal}
            />

            <section className='PageIndex_header'>
                <Container className="mb-5 px-4 pb-4 PageIndex--container">
                    <div className="row">
                        <div className="col-md-5 col-12 text-center position-image">
                            <img src="/sensor.png" className="PageIndex_headerImg bg-image img-fluid" alt="img" />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                     <div className="col-sm-12 col-md-10 col-lg-10 col-12">
                        <div className="row">
                                <div className="col-12" className="text-center">
                                    <h4 className="text-dark mb-3">{locale('HOME_SECTION1_TITLE')}</h4>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                    <div className="section-border flex-grow-1 d-flex flex-column" style={{paddingTop: '2rem'}}>
                                        <span className="section-text-header">{locale('HOME_SECTION1_BOX1_TITLE')}</span>
                                        <div className="p-2 flex-grow-1 d-flex flex-column">
                                            <h4 className="mb-3 h4 fw-bold text-center text-radon">{locale('HOME_SECTION1_BOX1_SUBTITLE')}</h4>
                                            <p>{locale('HOME_SECTION1_BOX1_CONTENIDO')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-6 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                    <div className="section-border flex-grow-1 d-flex flex-column" style={{paddingTop: '2rem'}}>
                                        <span className="section-text-header">{locale('HOME_SECTION1_BOX2_TITLE')}</span>
                                        <div className="p-2 flex-grow-1 d-flex flex-column">
                                            <h4 className="mb-3 h4 fw-bold text-center text-radon">{locale('P_TEST_RADON_PROFESIONAL_JUSTIFICACION_CTE')}</h4>
                                            <p>{locale('HOME_SECTION1_BOX2_CONTENIDO')}</p>
                                        </div>
                                    </div>
                                </div>

                                 {showMore &&
                                 <>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                        <div className="flex-grow-1 d-flex flex-column">
                                             <ModalBasicoForm1/>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                        <div className="flex-grow-1 d-flex flex-column">
                                             <ModalProfesionalForm1/>
                                        </div>
                                    </div>

                                    <div className="col-md-12 col-12 p-3">
                                        <h4 className="text-center mb-3 text-dark">{locale('HOME_RADON_BASICO_TARIFA')}:</h4>
                                        <div className="text-start">
                                            <p className="mb-1 text-dark">
                                                {locale('HOME_TARIFA_DATALLE_1')}
                                            </p>
                                            <p className="mb-0 text-dark">
                                                {locale('HOME_TARIFA_DATALLE_2')}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                             <ModalBasicoForm2 
                                                handleOpenModal_Tarifa={handleOpenModal_Tarifa}
                                             />
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                             <ModalProfesionalForm2 
                                              handleOpenModal_Tarifa={handleOpenModal_Tarifa}
                                             />
                                    </div>

                                     <div className="col-sm-12 col-md-12 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                        <div className="flex-grow-1 d-flex flex-column">
                                             <ModalBasicoForm3 />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-6 col-12 d-flex flex-column align-items-stretch p-3">
                                        <div className="flex-grow-1 d-flex flex-column">
                                             <ModalProfesionalForm3 />
                                        </div>
                                    </div>
                                 </>
                                }   
                          
                               {/*}     

                               <Col md={5} className="d-flex flex-column">
                                    <div className="section-border flex-grow-1 d-flex flex-column">
                                        <span className="section-text-header">{locale('HOME_SECTION1_BOX1_TITLE')}</span>
                                        <div className="p-2 flex-grow-1 d-flex flex-column">
                                            <h4 className="mb-3 h4 fw-bold text-center">{locale('HOME_SECTION1_BOX1_SUBTITLE')}</h4>
                                            <p>{locale('HOME_SECTION1_BOX1_CONTENIDO')}</p>
                                        </div>
                                    </div>

                                    {showMore && (
                                        <div className="mt-3 d-flex flex-column">
                                            <div className="flex-grow-1 d-flex flex-column">
                                                <div className="p-3 w-100 content-detail flex-fill">
                                                    <ModalBasicoForm1 />
                                                </div>
                                                <div className="p-3 w-100 content-detail flex-fill">
                                                    <ModalBasicoForm2 />
                                                </div>
                                                <div className="p-3 w-100 content-detail flex-fill">
                                                    <ModalBasicoForm3 />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>

                                <Col md={5} className="d-flex flex-column">
                                    <div className="section-border flex-grow-1 d-flex flex-column">
                                        <span className="section-text-header">{locale('HOME_SECTION1_BOX2_TITLE')}</span>
                                        <div className="p-2 flex-grow-1 d-flex flex-column">
                                            <h4 className="mb-3 h4 fw-bold text-center">{locale('P_TEST_RADON_PROFESIONAL_JUSTIFICACION_CTE')}</h4>
                                            <p>{locale('HOME_SECTION1_BOX2_CONTENIDO')}</p>
                                        </div>
                                    </div>

                                    {showMore && (
                                        <div className="mt-3 d-flex flex-column">
                                            <div className="flex-grow-1 d-flex flex-column">
                                                <div className="p-3 w-100 content-detail flex-fill">
                                                    <ModalProfesionalForm1 />
                                                </div>
                                                <div className="p-3 w-100 content-detail flex-fill">
                                                    <ModalProfesionalForm2 />
                                                </div>
                                                <div className="p-3 w-100 content-detail flex-fill">
                                                    <ModalProfesionalForm3 />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                */}




                                {
                                    !showMore &&
                                    <div className="col-12 text-center">
                                        <Button variant="primary" onClick={() => setshowMore(true)}>{locale('VER_MAS')}</Button>
                                    </div>
                                }

                          </div>  
                      </div>
                  </div>


                    {/*
                        showMore &&
                        <>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalBasicoForm1 />
                                        </div>
                                    </div> 
                                </div>

                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalProfesionalForm1 />
                                        </div>
                                      </div>  
                                </div>
                            </div>

                            <div className="row py-3">
                                <div className="col-md-6">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalBasicoForm2 />
                                        </div>
                                      </div>  
                                </div>

                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalProfesionalForm2 />
                                        </div>
                                      </div>  
                                </div>
                            </div>

                            <div className="row py-3">
                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                            <ModalBasicoForm3 />
                                        </div>
                                      </div>  
                                </div>

                                <div className="col-md-6">
                                      <div className="row d-flex justify-content-center">
                                        <div className="col-md-10 col-12">
                                             <ModalProfesionalForm3 />
                                        </div>
                                      </div>  
                                </div>

                            </div>

                        </>
                    */}

                    {
                        showMore &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <Button variant="primary" onClick={() => setshowMore(false)}>{locale('OCULTAR')}</Button>
                            </div>
                        </div>
                    }

                    {lang === 'ES' &&
                        <div className="col-12 text-center mt-4 mb-3">
                            <p className="text-dark fw-bold">{locale('COLABORACION')}:</p>
                            <img src="/CGATE_2023.gif" className="img-fluid" style={{ maxWidth: '300px', width: '100%', height: 'auto' }} alt="CGATE 2023" />
                        </div>
                    }

                </Container>
            </section>
            
            <div className="mt-auto">
                <Footer />
            </div>

            {dataModal_Login.value &&
                <ModalLogin
                    data={dataModal_Login}
                    handleOpenModal_Password={handleOpenModal_Password}
                    handleClose={handleCloseModal_Login}
                />
            }

            {dataModal_Password.value &&
                <ModalPassword
                    data={dataModal_Password}
                    handleClose={handleCloseModal_Password}
                />
            }


            {dataModal_Contacto.value &&
                <ModalContacto
                    data={dataModal_Contacto}
                    handleClose={handleCloseModal_Contacto}
                />
            }

            {dataModal_Registro.value &&
                <ModalRegistro
                    data={dataModal_Registro}
                    handleClose={handleCloseModal_Registro}
                />
            }

            {dataModal_Profesional.value &&
                <ModalProfesional
                    data={dataModal_Profesional}
                    handleClose={handleCloseModal_Profesional}
                />
            }

            {dataModal_Basico.value &&
                <ModalBasico
                    data={dataModal_Basico}
                    handleClose={handleCloseModal_Basico}
                />
            }

            {dataModal_Info.value &&
                <ModalUsuarioInformacion
                    data={dataModal_Info}
                    handleClose={handleCloseModal_Info}
                />
            }

            {dataModal_Radon.value &&
                <ModalInfoRadon
                    data={dataModal_Radon}
                    handleClose={handleCloseModal_Radon}
                />
            }

            <ModalTablaTarifas
                data={dataModal_Tarifa}
                handleClose={handleCloseModal_Tarifa}
            />

        </section>
    );
};
