import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ButtonSpinner } from "components/ButtonSpinner/ButtonSpinner";
import { Alert } from "react-bootstrap";
import { useLang } from 'language'
import { useFetch } from 'hooks/useFetch';
import axios from 'axios'
import { AuthContext } from '../../../../../auth/AuthContext';
import { getDataFile, isStatusError } from 'helpers/helpers';

export const Resultado = ({ dataradon, setIndexFocusWizard }) => {

  const locale = useLang();

  const { user: { token, lang }, dispatch } = useContext(AuthContext);

  const [fetchDocumentoData, setFetchDocumentoData] = useState({ data: null, loading: null, error: null, status: null });

  const handleFetchFile = () => {

    const url = process.env.REACT_APP_API_URL + '/documentoRadon/' + dataradon.id

    setFetchDocumentoData({ data: null, loading: true, error: null, status: null })

    let config = { responseType: 'blob' };

    config = {
      ...config,
      headers: { 
        Authorization: `Bearer ${token}`,
       'Accept-Language': String(lang).toLowerCase() 
      }
    }

    axios.get(url, config).then((response) => {
      setFetchDocumentoData({
        loading: false,
        error: null,
        data: response,
        status: response,
      });
    })
      .catch((error) => {
        console.log(error);
        setFetchDocumentoData({ data: null, loading: false, error: true, status: null })
      })
  }

  useEffect(() => {

    if (fetchDocumentoData.data) {

      getDataFile(
        fetchDocumentoData,
        setFetchDocumentoData({ data: null, loading: false, error: null, status: null }),
        'File',
        'pdf'
      )

      // setshowStatus(false)
    }

  }, [fetchDocumentoData])


  return (
    <div className='pb-0 mt-3 animate__faster animate__animated animate__fadeInLeft'>
      <div className="row">

        <div className="col-12">
          <h5 className='mb-2 pb-4 text-center'>{locale('RESULTADO')}</h5>
        </div>

        <div className="col-12 mb-1">
          <div className="mx-auto pb-3 text-center">
            <Alert variant='success' className='py-1'>
              <small>
                {locale('TEST_RADON_ANALISIS_OK')}
              </small>
            </Alert>
          </div>
          <fieldset className="border p-2">
            <legend className="w-auto text-left">{locale('TEST_RADON_RESULTADO_SOLICITUD')}</legend>

            <div className="col-12 mt-2 text-center">
              <ButtonSpinner
                variant="primary-radon" type="submit" className="mx-auto px-3 mb-2"
                handleSubmit={handleFetchFile} fetchDataLoading={[fetchDocumentoData.loading]}
                value={locale('TEST_RADON_DESCARGAR_INFORME')} icon={<FontAwesomeIcon icon={faFile} className="me-2" />}
              />
            </div>

            <div className="col-12 text-center">

              {
                dataradon.tipo_solicitud === 'profesional' &&
                <span
                  className='btn-link color-primary cursor-pointer text-decoration-none my-3 d-block'
                  onClick={() => setIndexFocusWizard(3)}
                >
                  {locale('P_GESTIONAR_PLANOS_CLICK_AQUI')}
                </span>
              }
            </div>

          </fieldset>
        </div>

        <div className="col-12 mt-2">
          <div className="d-flex flex-nowrap justify-content-between align-items-center">
            <div className='m-0'>
              <ButtonSpinner
                variant="outline-primary-radon" type="submit" className="px-3"
                handleSubmit={() => setIndexFocusWizard(3)} fetchDataLoading={[false]}
                value={locale('TEST_RADON_VOLVER')} icon={<FontAwesomeIcon icon={faArrowLeft} className="me-1" />}
              />

            </div>
          </div>
        </div>

      </div>
    </div>
  );
}