import React, {useEffect, useContext} from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { ButtonSpinner } from 'components/ButtonSpinner/ButtonSpinner'
import { useLang } from 'language';

export const ModalUsuarioInformacion = (props) => {
	const locale = useLang();
    const {data, handleClose} = props;
	
	return (
		<Modal show={data.value} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className='h5'>{locale('INFO_USUARIO_TITULO')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="container">
               <div className="row"> 

                <b>{locale('INFO_USUARIO_SUBTITULO')}</b>
               <p className="mt-3">
                
                <b>{locale('INFO_USUARIO_PASO1')}</b> {locale('INFO_USUARIO_PASO1_TEXT')}<br/><br/>

                {/* <p className="ms-2 mb-3">
                    <ol class="my-list">
                      <li>{locale('INFO_USUARIO_PASO1_DETALLE1')}</li>
                      <li>{locale('INFO_USUARIO_PASO1_DETALLE2')}</li>
                      <li>{locale('INFO_USUARIO_PASO1_DETALLE3')}.</li>
                    </ol>
                </p> */}

                {/* <br/> */}
                <b>{locale('INFO_USUARIO_PASO2')}</b> {locale('INFO_USUARIO_PASO2_TEXT')}<br/><br/>
                <b>{locale('INFO_USUARIO_PASO3')}</b> {locale('INFO_USUARIO_PASO3_TEXT')}<br/><br/>
                <b>{locale('INFO_USUARIO_PASO4')}</b> {locale('INFO_USUARIO_PASO4_TEXT')}<br/><br/>
                <b>{locale('INFO_USUARIO_PASO5')}</b> {locale('INFO_USUARIO_PASO5_TEXT')}<br/><br/>
                <b>{locale('INFO_USUARIO_PASO6')}</b> {locale('INFO_USUARIO_PASO6_TEXT')}<br/><br/>
                <b>{locale('INFO_USUARIO_PASO7')}</b> {locale('INFO_USUARIO_PASO7_TEXT')}<br/><br/>
                <b>{locale('INFO_USUARIO_PASO8')}</b> {locale('INFO_USUARIO_PASO8_TEXT')}
               </p>
               </div>
             </div>  
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    {locale('HOME_BTN_CERRAR')}
                </Button>
            </Modal.Footer>
        </Modal>
	);
}