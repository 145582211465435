import { useLang } from "language";
import React from "react";

export const ModalProfesionalForm = () => {

    return (
        <>
            <ModalProfesionalForm1 />
            <ModalProfesionalForm2 />
            <ModalProfesionalForm3 />
        </>
    )
};

export const ModalProfesionalForm1 = () => {

    const locale = useLang()

    return (
        <div className="row">
            <p>
                {locale('TEST_RADON_PROFESIONAL')}:
            </p>
            <p>
                <span className="fw-bold">{locale('HOME_RADON_BASICO_TEXT_SPAN2')}</span> {locale('P_MEDICION_DISPONIBLE_MESES')}.
            </p>
        </div>
    )
};

export const ModalProfesionalForm2 = ({handleOpenModal_Tarifa}) => {

    const locale = useLang()

    return (
        <div className="row d-flex justify-content-center align-items-center">
            {/*<h4 className="text-center mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_PROFESIONAL_TARIFA')}</h4>*/}

            <div className="col-12 col-md-6 d-flex align-items-stretch">
                <div className="section-border text-center flex-grow-1 d-flex flex-column">
                    {/*<span className="section-text-header">{locale('HOME_RADON_PROFESIONAL_BOX_TITLE')}</span>*/}
                    <div className="p-2 flex-grow-1 d-flex flex-column">
                        <h5 className="text-radon">{locale('TEST_RADON_PROFESIONAL')}</h5>
                        <small className="text-radon fw-bold border-bottom pb-2 mb-2">{locale('HOME_RADON_PROFESIONAL_BOX_TITLE')}</small>
                        <div className="Tarifas_content border-bottom pb-2" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_PROFESIONAL_BOX_INFO1') }}></div>
                        <br />
                        <div className="Tarifas_content border-bottom mb-2 pb-2" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_PROFESIONAL_BOX_INFO2') }}></div>
                        <div className="mb-2 text-center text-muted" onClick={() => handleOpenModal_Tarifa({
                            action: 'PROFESIONAL_LARGA',
                        })} style={{cursor: 'pointer'}}>{locale('HOME_TARIFA_TABLA_TITULO')}</div>
                    </div>
                </div>
            </div>

            {/*
            <div className="col-12">
                <p className="mb-0 text-center">
                    <small>

                    {locale('CONSULTA_COLEGIOS_CONVENIO')} <span className="link-primary color-primary cursor-pointer text-lowercase">{locale('EN_ESTE_ENLACE')}</span> 
                    </small>
                </p>
            </div>
            */}
        </div>
    )
};

export const ModalProfesionalForm3 = () => {

    const locale = useLang()

    return (
        <div className="row">
            <h6 className="text-center mt-3 mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_PROFESIONAL_SERVICIO')}</h6>
            <div className="col-12 col-md-12 mb-3">
                <ol className="list-group list-group-flush">
                    <li className="list-group-item"><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO1')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO2')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO3')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO4')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_INFO5')}</small></li>
                </ol>
            </div>

            <h6 className="text-center mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_PROFESIONAL_SERVICIO_NO_INCLUYE')}</h6>
            <div className="col-12 col-md-12 mb-3">
                <ol className="list-group list-group-flush">
                    <li className="list-group-item"><small>{locale('HOME_RADON_PROFESIONAL_SERVICIO_NO_INCLUYE_INFO1')}</small></li>
                </ol>
            </div>


        </div>
    )
};
