import { useLang } from "language";
import React from "react";

export const ModalBasicoForm = () => {

    return (
        <>
            <ModalBasicoForm1 />
            <ModalBasicoForm2 />
            <ModalBasicoForm3 />
        </>
    )
};

export const ModalBasicoForm1 = () => {

    const locale = useLang();

    return (
        <div className="row">
            {/* <p>
                {locale('HOME_RADON_BASICO_TEXT')}
            </p> */}
            <p>
                {locale('HOME_RADON_BASICO_TEXT_INFO1')}
            </p>
            <p>
                <span className="fw-bold">{locale('HOME_RADON_BASICO_BOX2_TITLE')}</span>: {locale('HOME_RADON_BASICO_TEXT_INFO22')}
            </p>
            <p>
                <span className="fw-bold">{locale('HOME_RADON_BASICO_TEXT_SPAN1')}</span> {locale('HOME_RADON_BASICO_TEXT_INFO2')}
            </p>

        </div>
    )
};


export const ModalBasicoForm2 = ({handleOpenModal_Tarifa}) => {

    const locale = useLang();

    return (
        <div className="row d-flex align-items-center justify-content-center">
            <div className="col-12 col-md-6 d-flex align-items-stretch">
                <div className="section-border text-center flex-grow-1 d-flex flex-column">
                    {/*<span className="section-text-header">{locale('HOME_RADON_BASICO_BOX2_TITLE')}</span>}*/}
                    <div className="p-2 flex-grow-1 d-flex flex-column">
                        <h5 className="text-radon" dangerouslySetInnerHTML={{ __html: locale('TEST_RADON_BASIC_TITULO') }}></h5>
                        <small className="text-radon fw-bold border-bottom mb-2 pb-2">{locale('HOME_RADON_BASICO_BOX2_TITLE')}</small>
                        <div className="Tarifas_content border-bottom pb-2" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX2_INFO') }}></div>
                        <br />
                        <div className="Tarifas_content border-bottom mb-2 pb-2" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX2_INFO2') }}></div>
                        <div className="mb-2 text-center text-muted" onClick={() => handleOpenModal_Tarifa({
                                                action: 'BASICO_LARGA',
                                            })} style={{cursor: 'pointer'}}>{locale('HOME_TARIFA_TABLA_TITULO')}</div>
                    </div>
                </div>
            </div>

            <div className="col-12 col-md-6 d-flex align-items-stretch">
                <div className="section-border text-center flex-grow-1 d-flex flex-column">
                    {/*<span className="section-text-header">{locale('HOME_RADON_BASICO_BOX1_TITLE')}</span>*/}
                    <div className="p-2 flex-grow-1 d-flex flex-column">
                        <h5 className="text-radon" dangerouslySetInnerHTML={{ __html: locale('TEST_RADON_BASIC_TITULO') }}></h5>
                        <small className="text-radon fw-bold border-bottom mb-2 pb-2">{locale('HOME_RADON_BASICO_BOX1_TITLE')}</small>
                        <div className="Tarifas_content border-bottom pb-2" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX1_INFO') }}></div>
                        <br />
                        <div className="Tarifas_content border-bottom mb-2 pb-2" dangerouslySetInnerHTML={{ __html: locale('HOME_RADON_BASICO_BOX1_INFO2') }}></div>

                        <div className="mb-2 text-center text-muted" onClick={() => handleOpenModal_Tarifa({
                            action: 'BASICO_CORTA',
                        })} style={{cursor: 'pointer'}}>{locale('HOME_TARIFA_TABLA_TITULO')}</div>
                    </div>
                </div>
            </div>
            {/*
            <div className="col-12">
                <p className="mb-0 text-center">
                    <small>

                    {locale('CONSULTA_COLEGIOS_CONVENIO')} <span className="link-primary color-primary cursor-pointer text-lowercase">{locale('EN_ESTE_ENLACE')}</span> 
                    </small>
                </p>
            </div>
            */}

        </div>
    )
};

export const ModalBasicoForm3 = () => {

    const locale = useLang();

    return (
        <div className="row">
            <h6 className="text-center mt-3 mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_BASICO_SERVICIO')}</h6>
            <div className="col-12 col-md-12 mb-3">
                <ol className="list-group list-group-flush">
                    <li className="list-group-item"><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO1')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO2')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO3')}</small></li>
                    <li className="list-group-item"><small>{locale('P_RESULTADO_TEST_1')}</small></li>
                    <li className="list-group-item"><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO5')}</small></li>
                    {locale('HOME_RADON_BASICO_SERVICIO_INFO6') &&
                        <li className="list-group-item"><small>{locale('HOME_RADON_BASICO_SERVICIO_INFO6')}</small></li>
                    }
                </ol>
            </div>

            <h6 className="text-center mb-3" style={{ fontWeight: 'bold' }}>{locale('HOME_RADON_BASICO_SERVICIO_NO_INCLUYE')}</h6>
            <div className="col-12 col-md-12 mb-3">
                <ol className="list-group list-group-flush">
                    <li className="list-group-item"><small>{locale('HOME_RADON_BASICO_SERVICIO_NO_INCLUYE_INFO1')}</small></li>
                </ol>
            </div>
        </div>
    )
};


