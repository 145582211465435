import React from 'react'
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLang } from '../../../../language';

export const TestRadonDetalleItem = ({ item, fetchParametrosData, handleShowModalEliminarMuestra }) => {

    //const [fetchSolicitudesMuestras, fetchSolicitudesMuestrasData] = useFetch('GET', `/clientes/solicitudes`)
    const locale = useLang()

    let history = useHistory();

    const paramsEstado = {
        'radon_estado_borrador': locale('BORRADOR'),
        'radon_estado_pagado': locale('PAGADO'),
        'radon_estado_solicitado': locale('SOLICITADO'),
        'radon_estado_medicion': locale('MEDICION'),
        'radon_estado_pendiente_analisis': locale('PENDIENTE_ANALISIS'),
        'radon_estado_finalizado': locale('FINALIZADO'),
    }

    const getDireccion = (item) => {
        if (!item || !item.edificio) return '';
        const { direccion, numero, piso } = item.edificio;
        return [
            direccion || '',
            numero || '', 
            piso || ''
        ].filter(Boolean).join(' ');
    };

    // Uso
    const direccion = getDireccion(item);
    return (
        <>
            <tr key={item.id}>
                <td valign='middle'>{item.id}</td>
                <td valign='middle'>{direccion || 'Sin definir'}</td>
                <td valign='middle'>{item.total_sensores || 0}</td>
                <td valign='middle'>{paramsEstado[item.estado]}</td>
                <td valign='middle'>{item.estado_fecha}</td>
                <td valign='middle'>
                    {/* <OverlayTrigger overlay={<Tooltip id={`tooltip-ver-muestras-${item.solicitud_id}`}>Veure mostres</Tooltip>}>
                        <span className="d-inline-block">
                            <Button variant="secondary" size='sm' className='ms-3 my-1' onClick={() => handleShowSolicitudMuestras(item.solicitud_id)}>
                                <FontAwesomeIcon icon={faBook} />
                            </Button>
                        </span>
                    </OverlayTrigger> */}

                    {
                        // item.estado_solicitud_id !== 'analisis_finalizado' && item.estado_solicitud_id !== 'pendiente_analisis' && 
                        <OverlayTrigger overlay={<Tooltip id={`tooltip-ver-muestras-${item.id}`}>{locale('VER_SOLICITUD')}</Tooltip>}>
                            <span className="d-inline-block">
                                <Button variant="outline-primary-radon" size='sm' className='ms-3 my-1' onClick={() => history.push(`/test-radon/${item.id}`)}>
                                    <FontAwesomeIcon icon={faFile} />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    }
                    {/*
                        (item.estado === 'Borrador' || item.estado === 'radon_estado_borrador') && <OverlayTrigger overlay={<Tooltip id={`tooltip-eliminar-${item.id}`}>Eliminar sol·licitud</Tooltip>}>
                            <span className="d-inline-block">
                                <Button variant="default" size='sm' className='ms-3 my-1' onClick={() => handleShowModalEliminarMuestra(item.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            </span>
                        </OverlayTrigger>
                    */}
                </td>
            </tr>
        </>
    )
}
