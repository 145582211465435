import { useContext } from 'react'
import { AuthContext } from '../auth/AuthContext';

let language = ''

export const useLang = (langDefault=null) => {

    const { user: { lang } } = useContext(AuthContext)

    const Lang = (text) => {
        const langInit = lang ? lang : langDefault;
        if (langInit) {
    
            if (language[langInit]) {
    
                return language[langInit][text]
    
            } else {
    
                language = require(`./${langInit}`).default;
            }
    
            return language[langInit][text]
        }
    
    }

    return Lang;
}
